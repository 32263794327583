import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

export const researchQuery = graphql`
  query researchQuery {
    wpPage(databaseId: { eq: 81 }) {
      title
      content
      research {
        fieldGroupName
        projectSection {
          projectSectionTitle
          project {
            projectInfo {
              projectInfoContent
              projectInfoHeading
            }
            projectWebsiteUrl
            otherInfo
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 140)
                }
              }
            }
          }
        }
        fundingSection {
          project {
            banner {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 450)
                }
              }
            }
            projectInfo {
              infoContent
              infoHeading
            }
          }
          title
        }
      }
    }
  }
`

export default function Research({ data }) {
  const researchSections = data.wpPage.research.projectSection
  const fundingSections = data.wpPage.research.fundingSection

  return (
    <Layout
      bodyClass="research"
      heroTitle={data.wpPage.title}
      heroText={data.wpPage.content}
      bcText="RESEARCH"
    >
      {researchSections.map(section => (
        <section className="research-project-section">
          <Container>
            <Row className="participation">
              <Col sm={12} lg={3}>
                {section.projectSectionTitle && (
                  <h3>{section.projectSectionTitle}</h3>
                )}
              </Col>
              <Col sm={12} lg={9}></Col>
            </Row>
            <Row>
              <Col sm={12} lg={3} className="red-col">
                <span className="red-subtitle">PROJECTS</span>
              </Col>
              <Col sm={12} lg={9}>
                {section.project &&
                  section.project.map(project => (
                    <div className="research-project">
                      {project.image && (
                        <GatsbyImage
                          image={getImage(project.image.localFile)}
                          alt="project logo"
                          className="research-project-logo"
                        />
                      )}
                      {project.projectInfo &&
                        project.projectInfo.map(info => (
                          <div className="research-box">
                            <h3>{info.projectInfoHeading}</h3>
                            <p>{info.projectInfoContent}</p>
                          </div>
                        ))}
                      {project.projectWebsiteUrl && (
                        <div className="research-box">
                          <h3>Project Website</h3>
                          <a
                            href={project.projectWebsiteUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {project.projectWebsiteUrl}
                          </a>
                        </div>
                      )}
                      {project.otherInfo && (
                        <div className="research-box">
                          <p>
                            <em>{project.otherInfo}</em>
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
              </Col>
            </Row>
          </Container>
        </section>
      ))}
      {fundingSections.map(section => (
        <section className="research-project-section">
          <Container>
            <Row className="participation">
              <Col sm={12} lg={3}>
                {section.title && <h3>{section.title}</h3>}
              </Col>
              <Col sm={12} lg={9}></Col>
            </Row>
            <Row>
              <Col sm={12} lg={3} className="red-col">
                <span className="red-subtitle">PROJECTS</span>
              </Col>
              <Col sm={12} lg={9}>
                {section.project &&
                  section.project.map(project => (
                    <div className="research-project">
                      {project.banner && (
                        <GatsbyImage
                          image={getImage(project.banner.localFile)}
                          alt="project banner"
                          className="research-project-logo"
                        />
                      )}
                      {project.projectInfo &&
                        project.projectInfo.map(info => (
                          <div className="research-box">
                            <h3>{parse(info.infoHeading)}</h3>
                            <p>{parse(info.infoContent)}</p>
                          </div>
                        ))}
                    </div>
                  ))}
              </Col>
            </Row>
          </Container>
        </section>
      ))}
    </Layout>
  )
}
